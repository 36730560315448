import { getCookie, setCookie } from "../../src/services/utility.service"

/**
 * method to check if param or cookie is set
 * @param {*} urlSearchParams
 * @param {*} type
 * @returns
 */
const manageHeaderFooterQueryParams = (urlSearchParams, type) => {
  const hideHeaderFooterParams = urlSearchParams.get(type) === 'true'
  const hideHeaderFooterParamsCookie = getCookie(type) === 'true'
  if(hideHeaderFooterParams && !hideHeaderFooterParamsCookie) {
    setCookie(type, "true", 365)
  }
  return (hideHeaderFooterParams || hideHeaderFooterParamsCookie)
}

/**
 * Method for finding hideheader footer params and show hide them based on value
 * @returns boolean for header param
 */
const hideHeaderFooterBasedOnParams = () => {
  const urlSearchParams = new window.URLSearchParams(window.location.search)
  const hideHeaderParam = manageHeaderFooterQueryParams(urlSearchParams, 'hideHeader')
  const hideFooterParam = manageHeaderFooterQueryParams(urlSearchParams, 'hideFooter')
  const hideHeaderFooterParam = manageHeaderFooterQueryParams(urlSearchParams, 'hideHeaderFooter')

  if(hideFooterParam || hideHeaderFooterParam) {
    document.getElementById('footer-content')?.classList.add('d-none')
  }
  if(hideHeaderParam || hideHeaderFooterParam) {
    document.getElementById('header-content')?.classList.add('d-none')
  }
}

export default hideHeaderFooterBasedOnParams