import React, { useEffect } from "react"
import PropTypes from "prop-types"
import loginHelper from "../utils/login-helper"
import {  setClickEventOnLogoutLink } from "../utils/logout-helper"
import hideHeaderFooterBasedOnParams from "../utils/hide-header-footer-params"

/**
 * Header component
 * @component
 * @param {Object} param0 List of props
 * @param {string} param0.content Header content
 * @param {string} param0.mobileMenuContent Mobile menu content
 * @example
 * return (
 *  <HeaderComponent content={ headerContent } />
 * )
 */
const HeaderComponent = ({
  content,
  mobileMenuContent
}) => {
  const addHeaderMobileMenuContent = () => {
    const headerMobileMenu = document.getElementById('wu-mobile-menu-content')

    if (
      headerMobileMenu &&
      mobileMenuContent
    ) {
      headerMobileMenu.innerHTML = mobileMenuContent
    }
  }

  useEffect(() => {
    addHeaderMobileMenuContent()
    loginHelper()
    hideHeaderFooterBasedOnParams()
    setClickEventOnLogoutLink()
  },[])

  return (
    <div id="header-content" className="entry-content" dangerouslySetInnerHTML={{ __html: content }}></div>
  )
}

HeaderComponent.propTypes = {
  content: PropTypes.string,
  mobileMenuContent: PropTypes.string
}
export default HeaderComponent